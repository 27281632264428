import './App.scss';
import 'font-awesome/css/font-awesome.min.css';
import React, {useEffect, useState} from "react";
import YandexMap from "./YandexMap";
import {YMInitializer} from "react-yandex-metrika";
import ym from 'react-yandex-metrika';

const App = () => {

  const [isListShown, setIsListShown] = useState(false);

  useEffect(() => {
    ym('hit', "/", {});
  }, [])

  const smoothScrollTo = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    })
  };

  return (
    <div className="body" onClick={()=>
     isListShown ? setIsListShown(false):{}}>
      <div className="body_background">
        <span id="Logo" />
        <div className="body_background_header">
          <div className="body_background_header_menu">
            <span onClick={() => smoothScrollTo('Logo')} className="body_background_header_menu_logo">
              <img src={require("./pictures/logo.png")} alt="" height="50"
                   className="body_background_header_menu_logo"/>
            </span>
            <div className="body_background_header_menu_text">
              <span className="body_background_header_menu_text" onClick={() => smoothScrollTo('О нас')}>О нас</span>
              <span className="body_background_header_menu_text"  onClick={() => smoothScrollTo('Услуги')}>Услуги</span>
              <span className="body_background_header_menu_text"  onClick={() => smoothScrollTo('Акции')}>Акции</span>
              <span className="body_background_header_menu_text"  onClick={() => smoothScrollTo('Контакты')}>Контакты</span>
            </div>
            <div className="body_background_header_menu_burger" onClick={() => setIsListShown(!isListShown)}>
              <div className="body_background_header_menu_burger_one" />
              <div className="body_background_header_menu_burger_one" />
              <div className="body_background_header_menu_burger_one" />
            </div>
            {
              isListShown ?
                <div className="body_background_header_menu_list">
                  <span onClick={() => {
                    setIsListShown(false)
                    smoothScrollTo('О нас')
                  }}
                     className="body_background_header_menu_list_item">О нас</span>
                  <span onClick={() => {
                    setIsListShown(false)
                    smoothScrollTo('Услуги')
                  }}
                     className="body_background_header_menu_list_item">Услуги</span>
                  <span onClick={() => {
                    setIsListShown(false)
                    smoothScrollTo('Акции')
                  }}
                     className="body_background_header_menu_list_item">Акции</span>
                  <span onClick={() => {
                    setIsListShown(false)
                    smoothScrollTo('Контакты')
                  }}
                     className="body_background_header_menu_list_item">Контакты</span>
                </div> : null
            }
          </div>
        </div>


        <div className="body_background_container">
          <div className="body_background_container_maine">
            <h1 className="body_background_container_maine_title">Тверской центр автоматических <br/>трансмиссий</h1>
            <h3 className="body_background_container_maine_content">Ремонт и обслуживание АТ (АКПП, вариаторов, DSG).
              Полный комплекс услуг: диагностика (входная/выходная), дефектовка, подбор запчастей, замена
              трансмиссионной
              жидкости, эвакуация до нашего центра.
            </h3>
            <div className="body_background_container_maine_phone">
              <span className="body_background_container_maine_phone_text">Запись по телефону:</span>
              <div className="body_background_container_maine_phone_content">
                <a href="tel:89301704444" className="body_background_container_maine_phone_content_number">8 (930)
                  170-44-44</a>
              </div>
            </div>
          </div>
        </div>
      </div>


      <span id="Акции"/>
      <div className="body_body">
        <div className="body_body_title">Акции</div>
        <div className="body_body_container">
          <div className="body_body_container_one">
            <div className="body_body_container_one_left">
              <img src={require("./pictures/zaph.png")} alt="" className="body_body_container_one_left_png"/>
              <span className="body_body_container_one_left_text">Помощь в подборе запчастей</span>
            </div>
            <div className="body_body_container_one_right">
              <img src={require("./pictures/renn.png")} alt="" className="body_body_container_one_right_png"/>
              <span className="body_body_container_one_right_text">Бесплатная диагностика</span>
            </div>
          </div>
        </div>
        <div className="body_body_container_bottom">
          <img src={require("./pictures/bg.png")} alt="" className="body_body_container_bottom_png"/>
          <div className="body_body_container_bottom_text">Бесплатная замена масла</div>
        </div>
        <div className="body_body_container_block">
          <div className="body_body_container_block_avto">
            <img src={require("./pictures/avto.png")} alt="" className="body_body_container_block_avto_png"/>
            <span className="body_body_container_block_avto_text">Бесплатная эвакуация</span>
          </div>
          <div className="body_body_container_block">
            <div className="body_body_container_block_card">
              <span className="body_body_container_block_card_text">Скидка <span
                className="body_body_container_block_card_text_stile">100%</span></span>
            </div>
          </div>
        </div>
      </div>


      <span id="Услуги"/>
      <div className="body_body_two">
        <div className="body_body_two_title">Услуги</div>
        <div className="body_body_two_container">
          <div className="body_body_two_container_first">
            <div className="body_body_two_container_first_one">
              <img src={require("./pictures/one.jpeg")} alt="" className="body_body_two_container_first_one_png"/>
              <span className="body_body_two_container_first_one_tatle">Компьютерная <br/>диагностика</span>
              <span
                className="body_body_two_container_first_one_content">Полная <br/> диагностика механических и электронных <br/> систем автомата.</span>
            </div>
            <div className="body_body_two_container_first_two">
              <img src={require("./pictures/two.jpeg")} alt="" className="body_body_two_container_first_two_png"/>
              <span className="body_body_two_container_first_one_tatle">Замена масла в <br/>АКПП</span>
              <span className="body_body_two_container_first_one_content">Замена трансмиссионных жидкостей, <br/>фильтров, помп и <br/>т.д.</span>
            </div>
            <div className="body_body_two_container_first_three">
              <img src={require("./pictures/three.jpeg")} alt="" className="body_body_two_container_first_three_png"/>
              <span className="body_body_two_container_first_one_tatle">Ремонт АКПП</span>
              <span
                className="body_body_two_container_first_one_content">Полный спектр <br/>работ: <br/>диагностика,<br/> локальный и капитальный <br/>ремонты.</span>
            </div>
            <div className="body_body_two_container_first_four">
              <img src={require("./pictures/four.jpeg")} alt="" className="body_body_two_container_first_four_png"/>
              <span className="body_body_two_container_first_one_tatle">Ремонт КПП DSG</span>
              <span
                className="body_body_two_container_first_one_content">Полный спектр <br/>работ: <br/>диагностика,<br/> локальный и капитальный <br/>ремонты.</span>
            </div>
          </div>
          <div className="body_body_two_container_second">
            <div className="body_body_two_container_second_five">
              <img src={require("./pictures/five.jpeg")} alt="" className="body_body_two_container_second_five_png"/>
              <span className="body_body_two_container_second_five_tatle">Ремонт вариаторов</span>
              <span className="body_body_two_container_second_five_content">Полный спектр работ: диагностика, локальный и капитальный ремонты.</span>
            </div>
            <div className="body_body_two_container_second_six">
              <img src={require("./pictures/six.jpeg")} alt="" className="body_body_two_container_second_six_png"/>
              <span className="body_body_two_container_second_five_tatle">Ремонт гидроблоков АКПП (мехатроника)</span>
              <span className="body_body_two_container_second_five_content">Все работы производятся в кратчайшие сроки с гарантией в 6 мес.</span>
            </div>
            <div className="body_body_two_container_second_seven">
              <img src={require("./pictures/seven.jpeg")} alt="" className="body_body_two_container_second_seven_png"/>
              <span className="body_body_two_container_second_five_tatle">Ремонт гидротрансформаторов АКПП</span>
              <span className="body_body_two_container_second_five_content">Замена и восстановление гидротрансформаторов любых производителей.</span>
            </div>
            <div className="body_body_two_container_second_eight">
              <img src={require("./pictures/eight.jpeg")} alt="" className="body_body_two_container_second_eight_png"/>
              <span className="body_body_two_container_second_five_tatle">Ремонт ЭБУ <br/>АКПП</span>
              <span className="body_body_two_container_second_five_content">Полный комплекс работ по выгодным ценам. Гарантия на все восстановленные блоки.</span>
            </div>
          </div>
        </div>

        <span id="О нас"/>
        <div className="body_body_content">
          <div className="body_body_content_tatle"> Доверьте ремонт профессионалам</div>
          <div className="body_body_content_text">Наработанный опыт за более чем 17 лет, оснащение нашего центра
            современным диагностическим и ремонтным оборудованием, а также применение оригинальных запасных частей, дает
            нам возможность осущесвтлять ремонт автоматических коробок переключения передач (АКПП, вариаторных АКПП,
            DSG) на высочайшем уровне. <br/> <p className="body_body_content_text_post">Убедитесь в этом сами!</p></div>
        </div>

        <div className="body_body_question">
          <div className="body_body_question_text"><em><p className="body_body_question_text_tatle">Почему выбирают
            нас? </p>1. Уже более 17 лет мы занимаемся ремонтом коробок автомат - без преувеличений будет сказано, что
            мы знаем о них ВСЕ!
            <br/>2. Мы оснащены современных дилерским диагностическим и ремонтным оборудованием, получение
            положительного результата, без которого невозможно.
            <br/>3. Даем гарантию на запчасти (если приобретены у нас) и ремонтные работы сроком 6 мес.
            <br/>4. Гарантируем самые короткие сроки. В среднем ремонт у нас занимает от 2 дней.
            <br/>5. Удобные условия оплаты. Предусмотрены все виды платежей (нал / безнал).
            <br/>6. Охраняемая парковка. Оставляя свой авто у нас, вы можете быть спокойны за его сохранность.
            <br/>7. Эвакуация до нашего технического центра. В случае, если ремонт планируется проводить у нас, то мы
            предоставляем услуги эвакуатора совершенно бесплатно.
            <br/>8. Готовим своим клиентам приятные бонусы. Например диагностика, замена трансмиссионных жидкостей, при
            ремонте БЕСПЛАТНО!</em></div>
          <img src={require("./pictures/garant.png")} alt="" className="body_body_question_text_svg"/>
        </div>


        <div className="body_body_footer">
          <div className="body_body_footer_one"><p className="body_body_footer_one_tatle">О нашей работе</p>
            Ремонт автоматических коробок передач (АКПП) - это очень сложная и ответственная процедура. Доверяйте ее
            только профессионалам, таким, как мы. Мы более 17 лет посвятили ремонту автоматических трансмиссий и знаем
            все о них.
          </div>
          <div className="body_body_footer_two"><p className="body_body_footer_one_tatle">Связаться с нами</p>
            <ul className="body_body_footer_two_ul">
              <li>Lobanovservice@yandex.ru</li>
              <span id="Контакты"/>
              <li><a href="tel:+79206964159" className="body_body_footer_two_number">8(930)170-44-44</a></li>
            </ul>
          </div>
          <div className="body_body_footer_three"><p className="body_body_footer_one_tatle">Приезжайте</p>
            Тверь, деревня Кривцово, 84А
           <YandexMap/>
            <a href="https://api.whatsapp.com/send?phone=+79301704444" target="_blank" rel="noopener noreferrer"><i></i>
              <div type="button" className="whatsapp-button">
                <div className="text-button"><i className="fa fa-whatsapp"/><span>WhatsApp</span></div>
              </div>
            </a>
          </div>
        </div>

        <YMInitializer accounts={[89155809]} />
      </div>
    </div>
  );
}

export default App;
