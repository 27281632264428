import React, {useEffect} from "react";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import './App.scss'

const center = [56.808882, 35.871530];

const images = [...Array(26)].map((n, i) => {
  const letter = String.fromCharCode(i + 97);
  return `https://img.icons8.com/ios-filled/2x/marker-${letter}.png`;
});

const YandexMap = () => {
  return (
    <div className="ya-map-width">
      <YMaps query={{ load: "package.full" }} >
        <Map
          state={{
            center,
            zoom: 15,
            controls: []
          }}
          width="10vw"
          height="17vh"
          onClick={() => window.location.href = 'https://yandex.ru/navi/14/tver/?from=navi&lang=ru&ll=35.870037%2C56.808169&mode=whatshere&whatshere%5Bpoint%5D=35.871402%2C56.809085&whatshere%5Bzoom%5D=17&z=15.4'}
        >
          <Placemark
            geometry={center}
            options={{
              iconLayout: "default#image",
              iconImageSize: [50, 50],
              iconImageHref: images
            }}
          />
        </Map>
      </YMaps>
    </div>
  );
}

export default YandexMap
